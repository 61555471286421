<template>
  <div id="layout-login" class="login-container">
    <div class="login-container__img">
      <img 
        src="@/assets/img/tela-inicial/logo.svg" 
        alt="Wap Store implantação" 
        width="453" 
        height="114"
      >
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
    name: "LayoutLogin",
}
</script>

<style>
/* ESTILOS MENSAGEM DE ERRO */
.login__error-message {
  margin-bottom: 12px;
  max-width: 363px;
}
/****************************/

.login-container {
  display: flex;
  justify-content: center;
  background-color: #F5F5F5;
  min-height: 100vh;
}
.login-container__img {
  background-color: #E85128;
  max-width: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-container-form {
  align-self: center;
  max-width: 50%;
  width: 100%;
  padding: 92px 80px 40px;
}
.login-container__form-text {
  margin-bottom: 40px;
}
.login-container__form-text h1 {
  font-family: 'Rubik', sans-serif;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.3;
  color: #E85128;
  margin-bottom: 0;
}
.login-container__form-text p {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  color: #1C1C1C;
  margin-bottom: 0;
}
.login-container-form label {
  font-size: 16px;
  color: #404040;
}
.login-container-form label.error {
  color: #EF5350;
}

.login-container-form label.error input {
  border-color: #EF5350;
  color: #EF5350;
}
.login-container-form input:not(.input-password__custom) {
  border: 1px solid #707070;
  height: 44px;
  width: 100%;
  max-width: 363px;
  text-indent: 20px;
  color: #404040;
  background: #FFFFFF;
  border-radius: 0;
  margin-bottom: 20px;
}
.login-container-form input ::placeholder {
  color: #404040;
}
.login-container-form button,
.login-container--btn-to-login {
  max-width: 363px;
  width: 100%;
  background: #E85128;
  height: 50px;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 20px;
}
.login-container-form button.disabled {
  cursor: default;
  opacity: 0.3;
}
.login-container-form-seguro {
  margin-top: 85px;
}
.login-container-form-seguro span {
  color: #4AAE51;
  font-size: 15px;
  display: flex;
  align-items: flex-end;
  gap: 5px;
}
.login-container-form-seguro p {
  font-size: 10px;
  color: #404040;
  line-height: 1.4;
}
</style>